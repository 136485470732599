<template>
  <div class=" container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-sm justify-between items-end">
        <div class="text-h4">Global Vendor List</div>
      </div>

      <div class="row q-mb-xl">
        <div class="col-12 text-body2 q-my-md">Select a Vendor to edit. You can only edit vendors that have been created by your Company</div>
        <div class="col-12 text-body2 q-my-md">To add a vendor, type the vendor's name in the select box and hit enter</div>
      </div>

      <div class="row q-mt-xl full-width">
        <VendorSelect class="full-width" v-bind:selection.sync="selection"/>
      </div>

      <q-dialog v-model="showDialog">
        <q-card style="min-width: 568px" class="q-pa-sm bg-lmsBackground primaryText-text">
          <!-- can only update/delete if owner or if Master admin -->
          <q-form @submit="updateVendor" autocomplete="off">
            <q-card-section>
              <div class="text-h5">Edit Vendor</div>
            </q-card-section>
            <q-card-section>
              <q-input v-model="vendor.name" label="Vendor Name" :disable="!(vendor.customer_uid === $store.getters['auth/user'].company.uid || $isAllowed(['||', 'master-admin'], true))" />
            </q-card-section>
            <q-card-section class="row justify-between items-end q-mt-xl" v-if="vendor.customer_uid === $store.getters['auth/user'].company.uid || $isAllowed(['||', 'master-admin'], true)">
              <!-- cant delete if being assigned to a module -->
              <q-btn flat @click="deleteVendor">Delete</q-btn>
              <!-- should we do the same for update as delete -->
              <q-btn color="primary" type="submit">Update</q-btn>
            </q-card-section>
          </q-form>
        </q-card>
      </q-dialog>

    </div>
  </div>
</template>

<script>
import VendorSelect from 'components/VendorSelect'

export default {
  components: { VendorSelect },
  data () {
    return {
      vendor: {},
      selection: null,
      showDialog: false
    }
  },
  watch: {
    selection (vendor) {
      if (vendor && vendor.name) {
        this.vendor = vendor
        this.showDialog = true
      } else {
        this.vendor = {}
      }
    }
  },
  methods: {
    async updateVendor () {
      this.$loading(true, 'Updating Vendor')
      try {
        await this.$axios.post(`${this.$consts.UPDATE_GLOBAL_VENDOR_LIST_URL}/${this.vendor.uid}`, this.vendor)
        this.$successMsg()
        this.showDialog = false
        this.selection = null
        this.vendor = null
        this.$loading(false)
      } catch (e) {
        this.$loading(false)
      }
    },
    async deleteVendor () {
      this.$loading(true, 'Deleting Vendor')
      try {
        await this.$axios.post(`${this.$consts.DELETE_GLOBAL_VENDOR_LIST_URL}/${this.vendor.uid}`, this.vendor)
        this.$successMsg()
        this.showDialog = false
        this.selection = null
        this.vendor = null
        this.$loading(false)
      } catch (e) {
        this.$loading(false)
      }
    }
  },
  created () {

  }
}
</script>
